<template>
  <div>
    <div v-for="(data, index) in fakeData" :key="index">
      <!-- <img :src="data.img" alt="" /> -->
      <h1>{{ data.name }}</h1>
      <h1>{{ data.message }}</h1>
    </div>
  </div>
</template>

<script>
// import MessageService from "@/services/messages/message.service";

export default {
  name: "MainMessage",
  data() {
    return {
      fakeData: [
        {
          img: "https://images.unsplash.com/photo-1552374196-c4e7ffc6e126?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80",
          name: "Tony",
          message: "需先輸入名字才能夠開始聊天，聊天時，自己的名字會以灰色顯示，其他人的名字會以深藍色顯示",
        },
      ],
    };
  },
  async created() {
    // await MessageService.start();
    // MessageService.getMessageGroup().then(x => console.log(x));
  },
};
</script>

<style></style>
